import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';

import { Section, Button, Title, Text, Box } from '../components/Core';
import PageWrapper from '../components/PageWrapper';
import CTA from '../sections/landing4/CTA';
import imgS1 from '../assets/image/jpg/parvekelasitukset/img1.jpg';
import imgS2 from '../assets/image/jpg/parvekelasitukset/img2.jpg';
import { device } from '../utils';

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section hero>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Title className="my-4">Parvekelasit</Title>
                  {/* <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    To satisfy our goal of creating a brand that could adapt across many use cases,
                    we took the base form of their new logo and used it as an artboard. As long as
                    the structural outline of the mark stayed consistent their brand was allowed to.
                  </Text> */}
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>

        <div className="mt-0 pb-0">
          <Container>
            <Row className="justify-content-center">
              <Col xs="10" className="mb-5">
                <img src={imgS1} alt="" className="img-fluid w-100" />
              </Col>
              <Col xs="10" className="mb-5">
                <img src={imgS2} alt="" className="img-fluid w-100" />
              </Col>
              {/* <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img src={imgS3} alt="" className="img-fluid w-100" />
              </Col>
              <Col lg="6" className="mb-5 pl-lg-4">
                <img src={imgS4} alt="" className="img-fluid w-100" />
              </Col> */}
            </Row>
          </Container>
        </div>

        <Section bg="dark" className="pt-0">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Seuraavaksi
              </Text>
              <Link to="/terassi">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 2rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Terassi
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
